exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nagrody-js": () => import("./../../../src/pages/nagrody.js" /* webpackChunkName: "component---src-pages-nagrody-js" */),
  "component---src-pages-nawozy-js": () => import("./../../../src/pages/nawozy.js" /* webpackChunkName: "component---src-pages-nawozy-js" */),
  "component---src-pages-ogloszenia-js": () => import("./../../../src/pages/ogloszenia.js" /* webpackChunkName: "component---src-pages-ogloszenia-js" */),
  "component---src-pages-paliwa-js": () => import("./../../../src/pages/paliwa.js" /* webpackChunkName: "component---src-pages-paliwa-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-pages-zboza-js": () => import("./../../../src/pages/zboza.js" /* webpackChunkName: "component---src-pages-zboza-js" */)
}

